
.footer {
    width: 100%;
    height: auto;
    position: relative;
    background-color: #92b1d3;  
  }
  


.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}


.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    width: 200px;
    height: auto;
}

.footer .top .logo-footer img {
    width: 100%;
    height: auto;
}

.footer .top .icon {
    font-size: 3rem;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin: auto;
}

.col {
    width: max-content;
    padding: 0 1rem;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;
}

.footer h3 {
    margin: 1rem 0 2rem 0;
    text-transform: uppercase;
    text-align: center;
    color: black;
    font-size: 1.2rem;
}


.footer p {
    margin: .5rem 0;
    color: rgb(255, 255, 255);
    text-align: center;

}

.copyright p {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
}

.license {
    width: 100%;
    text-align: center;
}



.license h3 {
    text-align: center;
}

.license p {
    text-align: center;
    color: white;
}

.license a {
    text-align: center;
    color: white;
}

.license a:hover {
    text-align: center;
    color: rgb(4, 97, 219);
}


.license-image {
    width: 150px;
    margin: auto;
    padding: .5rem;
}

.footer .license img {
    width: 100%;
}



@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }





}


