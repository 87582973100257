@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');

.navbar {
    width: 100%;
    z-index: 10;
    background-color: #92b1d3;
    position: relative;
    text-align: center;
}

.nav-box {
    width: 100%;
    display: flex;
    justify-content: center;

}


.nav-box ul {
  margin: auto 0;
}

.navbar-logo {
  width: 250px;
  z-index: 2;
}


.navbar-logo img {
  width: 100%;
  height: 100%;
}

.logo-hide {
  display: none;
}

.navbar .icon {
  font-size: 1.4rem;
  color: rgb(255, 255, 255);
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
    margin: auto 0;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 1rem;
}

.navbar li {
    width: max-content;
    padding: 0 1.5rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.navbar li a {
  color: rgb(255, 255, 255);
  font-family: 'Varela', sans-serif;  
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  
}

.navbar li a:hover {
  color: #767a49 !important;
  font-weight: 500;
}

  .menu-item .sub__menus li {
    border-bottom: 1px solid #e4e8ec;
    width: 100%;
    padding: 1rem 1.5rem;
  }

  .menu-item .sub__menus li:hover {
    border-bottom: 1px solid #c2c5a3;
    width: 100%;
  }


  .menu-item .sub__menus li a {
    color: #ffffff;
    font-size: 13px;
    font-weight: 100;
  }

  .menu-item .sub__menus li a:hover {
    color: #767a49 !important;
    font-weight: 500;
  }




/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background-color: #92b1d3;
    width: max-content;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
  }



  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    top: 10px;
  }



@media screen and (max-width: 1200px) {


  .nav-box {
    justify-content: space-between;
}

    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 75%;
        height: 100vh;
        top: 0;
        left:-100%;
        justify-content: center;
        background: #92b1d3;
        transition: 0.5s ease-in;
        color: white;
    }


    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1.5rem;
    }

    .sub__menus {
      background-color: #7093b8;
      width: 100%;
      left: 0;
    }

    .menu-item .sub__menus li {
      padding: .1rem 1.5rem;
      left: 0;
      width: 100%;
    }
  

    .menu-item .sub__menus li a {
        font-size: 14px;
      }


}

