@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact-hero {
    width: 100%;
    height: 60vh;
    position: relative;
    background: rgba(148, 90, 35, 0.274);
  }
  
  .contact-hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-contact-min.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.contact-hero .contact-hero-content {
    display: block;
    position: absolute;
    bottom: 20%;
    left: 5%;
    padding: 1rem;
}

.contact-hero h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.contact-hero h2 {
    font-size: 3rem;
    color: white;
}

.contact-hero p {
    font-family: 'Montserrat', sans-serif;
    font-family: 'Montserrat', sans-serif;
    color: white;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.contact-hero h2 {
    color: white;
    font-size: 1.5rem;
}

.contact-hero h1 {
    font-size: 3rem;
}

.contact-hero .contact-hero-content {
    bottom: 10%;
    left: 0;
    text-align: center;
}

}