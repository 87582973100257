@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto+Slab&family=Roboto:wght@300&display=swap');

.hero-bg {
  width: 100%;
  height: auto;
  position: relative;
}

.hero-bg:before {
  content: '';
  position: absolute;
  background: url('../../assets/hero-bg-wave.png') no-repeat center bottom/cover;
  height: 100%;
  width: 100%;
  opacity: 0.3;
}


.hero {
  display: flex;
  justify-content: center;
}

.slider {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.hero-caption {
  margin: auto;
  text-align: center;
  /* position: absolute; */
  bottom: 20%;
  z-index: 2;
}

.hero-right {
  /* position: relative; */
  width: 500px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.13);
}

.hero-logo {
  width: 100%;
  margin: auto;
}

.hero-logo img {
  width: 100%;
}

.hero-caption h1 {
  color: #181616c7;
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 2rem;
  font-family: 'Cabin', sans-serif;
  padding: 1rem 0 0 0;
}

.hero-caption p {
  font-size: 1.5rem;
  color: #000000;
  font-family: 'El Messiri', sans-serif;
}

.hero-caption h2 {
  color: #494945;
}


.slider ul{
	margin:0;
	padding:0;
	list-style:none;
  position: relative;
}

.slider ul li {
  position: absolute;
	width:100%;
	height:80vh;
	top:0;
	left:0;
	animation-iteration-count:infinite;
	animation-duration:24s;
}

.slider img {
	width:100%;
  height: 100%;
  object-fit: cover;
}

.slide1 {
  position: absolute;
  z-index: 2;
}

.slider ul li:nth-child(1) {
	animation-name:slider;
	animation-delay:-3s;
  opacity:0;
  position: relative;
}
.slider ul li:nth-child(2){
	animation-name:slider;
	animation-delay:5s;
	opacity:0;
}
.slider ul li:nth-child(3){
	animation-name:slider;
	animation-delay:13s;
	opacity:0;
}


@keyframes slider{
	0%{
		opacity:0;
	}
	20.83%{
		opacity:1;
	}
	33.33%{
		opacity:1;
	}
	45.83%{
		opacity:0;
	}
	100%{
		opacity:0;
	}	
}


/* Button */

button {
  padding: 12.5px 30px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  background-color: #c2c5a3;
  letter-spacing: 2px;
  font-weight: Bold;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

button:hover {
  background-color: #92b1d3;
  border: none;
}

button:active {
  background-color: #3d94cf;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  box-shadow: none;
}

.hero-headline-hide {
  display: none;
}

@media screen and (max-width: 750px) {
  .hero {
    flex-wrap: wrap;
  }

  /* .hero-logo {
    display: none;
  } */

  .hero-btn {
    display: none;
  }

  .hero-headline {
    display: none;
  }

  .hero-headline-hide {
    display: block;
  }

  .hero-right .hero-headline-hide h1 {
    font-size: 1rem;
    color: #92b1d3;
  }

  .slider ul li {
    position: absolute;
    width:100%;
    height:60vh;
    top:0;
    left:0;
    animation-iteration-count:infinite;
    animation-duration:24s;
  }
  

  .hero-logo img {
    width: 50%;
    padding-bottom: 1rem;
  }

  /* .hero-caption {
    bottom: 5%;

  } */
  
  .hero-caption h1 {
    font-size: 1.5rem;
    /* color: #ffffff; */
  }

  .hero-caption p {
    font-size: 1.2rem;
    /* color: #ffffff; */
    font-family: 'El Messiri', sans-serif;
  }


  .hero-right {
    /* position: absolute; */
    height: 100%;
    /* background-color: #1a0e015d; */
  }


}


