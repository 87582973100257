@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.therapy-sa {
    width: 100%;
    height: auto;
    padding: 3rem 0 0 0;
}
  

  .therapy-sa-header h1 {
    text-align: center;
    color: rgb(27, 26, 26);
    font-family: 'Bacasime Antique', serif;
    font-size: 2rem;
    color: #92b1d3;

}

.therapy-sa-header h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 3px;
    color: var(--primary-color);
    font-family: 'Bacasime Antique', serif;
  }

.therapy-sa-header p {
    text-align: center;
    color: rgb(34, 32, 32);
}


.therapy-sa-btn {
    text-align: center;
    padding: 1rem ;
}


.therapy-sa-btn button  {
    background-color: var(--primary-color);
    color: white;
    border: none;
    font-weight: 500;
}

.therapy-sa-btn button:hover  {
    background-color: #565e06;
    border: none;
    font-weight: 500;
}


.therapy-sa-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}


.sa-card {
    height: 300px;
    width: 300px;
    border-radius: 1rem;
    border: 5px solid #c2c5a3;
    position: relative;
    margin-top: 5rem;
}

.sa-card:hover {
    height: 300px;
    width: 300px;
    border-radius: 1rem;
    border: 5px solid #92b1d3;
    position: relative;
    margin-top: 5rem;
}


.sa-card .box-content {
    width: 100%;
    position: absolute;
    bottom: 0rem;
    text-align: center;
}

.sa-card .box-content img {
    width: 100%;
}

.sa-card h1 {
    font-size: 1rem;
    padding: .5rem;
}

.hide-btn {
    display: none;
}





@media screen and (max-width:940px) {

    .hide {
        display: none;
    }

    
}


@media screen and (max-width:700px) {



}

