
.sa-op {
    width: 100%;
    height: 60vh;
    background: rgba(114, 76, 34, 0.342);
    position: relative;
  }
  
  .sa-op:before {
    content: '';
    position: absolute;
    background: url('../../assets/programs-op-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }



  .sa-op {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sa-op .sa-op-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}


.sa-op h1 {
    color: white;
    font-size: 3rem;
    text-align: center;
    font-weight: 300;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .sa-op .sa-op-content h1 {
        font-size: 2rem;
    }
    
    }